.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.content {
  padding: 1rem;
  background-color: white;
  border: 1px solid #f0f0f0;
  max-width: 800px;
}
