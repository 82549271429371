.containerRow {
  height: 100vh;
  color: white !important;
}

.leftColumn {
  padding: 3rem;
  background-color: #009688;
}

.rightColumn {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: white;
}

.imageContainer {
  margin-top: 21vh;
  max-width: 260px;
}

.title {
  text-align: center;
  color: white;
}

.description {
  font-weight: 400 !important;
  color: white;
}

.quote {
  font-weight: 400 !important;
  font-style: italic;
  margin-bottom: 0.5rem;
}

.quoteAuthor {
  font-weight: 600;
  text-align: right;
}

.formContainer {
  width: 100%;
  max-width: 420px;
  padding: 2rem;
  border: 1px solid #d9d9d9;
}

.formTitle {
  text-align: center;
  margin-bottom: 1.5rem;
}

.termsConditions {
  font-size: 0.7rem;
}

.haveAccount {
  margin: 0;
  text-align: center;
  font-size: 0.8rem;
}
