body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Solway",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spinWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.content-title {
  margin-bottom: 1em;
}

/* overwrite ant design defaults */
.ant-layout {
  min-height: 100vh;
  background-color: #f9fafd !important;
}

.ant-layout-header {
  z-index: 1;
  padding: 0 1.5rem !important;
  height: 2.5rem !important;
  line-height: 2.5rem !important;
  background-color: white !important;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.2);
}

.ant-layout-content {
  padding: 1.5rem;
  background-color: #f9fafd !important;
}

.ant-layout-sider {
  background-color: #315176 !important;
}

.ant-menu-inline-collapsed {
  max-width: 60px;
}

.ant-layout-sider-trigger {
  background-color: #315176 !important;
}

.ant-menu {
  background-color: #315176 !important;
}

.ant-layout-footer {
  text-align: center;
  /* border-top: 1px solid rgba(152,166,173,.2); */
  background-color: #f9fafd !important;
  color: #616161 !important;
}

.ant-form-item-explain {
  font-size: 0.8rem !important;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-10 {
  margin-top: 10rem;
}
.ant-input-number {
  width: 100%;
}

.full-width {
  width: 100%;
}

.button-modified {
  border-radius: 60px;
}

/* A distinct border color on hover */
.button-modified:hover {
  border-color: #3b82f6; /* Blue border on hover for a modern touch */
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.00002);
}

.custom-notification {
  cursor: pointer;
}

.notification-content {
  display: flex;
  align-items: center;
}

.notification-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.header-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-controls > * {
  margin-left: 10px;
}

.header-row {
  flex-direction: row-reverse;
}

/* Notification Dropdown Container */
.notification-dropdown {
  max-height: 600px;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 12px;
}

.notification-item {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 8px;
  border-radius: 6px;
  color: #333;
  text-decoration: none;
  background-color: #f9f9f9;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.notification-item:hover {
  background-color: #1deb84;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.notification-item.unread {
  background-color: #f0f8ff;
  border-left: 4px solid #007aff;
  font-weight: bold;
}

.notification-item.read {
  background-color: #f9f9f9;
  border-left: 4px solid transparent;
  color: #666;
  font-weight: normal;
}

.notification-item strong {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 14px;
}

.notification-item p {
  font-size: 13px;
  color: #555;
  margin: 4px 0;
}

.notification-item small {
  font-size: 12px;
  color: #999;
  align-self: flex-end;
}

.mark-all-as-read-btn {
  width: 100%;
  background-color: lightgreen;
  color: black;
  font-weight: bold;
}
.mark-all-as-read-btn:hover {
  background-color: green;
  color: white;
  font-weight: bold;
}
.loading-indicator {
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.see-more {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: #007aff;
  color: white;
  font-weight: bold;
}
