.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.formContainer {
  width: 100%;
  max-width: 420px;
  padding: 2rem;
  border: 1px solid #d9d9d9;
}
