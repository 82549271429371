.label {
  font-weight: 600;
}

.paragraph {
  margin-bottom: 0.5em;
}

.verified {
  color: #479b1d;
  font-size: 13px;
}

.filterButton {
  color: #9e9e9e !important;
  background: none !important;
}

.filterButton:hover {
  color: black !important;
}

.filterButtonActive {
  color: black !important;
}
